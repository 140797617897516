import { generateOnlyid } from '@/public/methods';
import store from '@/store';
import { defineComponent, ref, Ref, watch } from '@vue/composition-api';

import { SvgPlay, SvgPlayCircle } from './svg';

export default defineComponent({
	name: 'MusicSongList',
	components: { SvgPlay, SvgPlayCircle },
	props: {
		list: {
			required: true,
		},
	},
	setup(props, { root }: any) {
		const listData: Ref<any> = ref([]);
		watch(
			() => props.list,
			newValue => {
				listData.value = newValue;
			},
			{ immediate: true, deep: true }
		);
		const timeToString = (time: number): string =>
			`${Math.floor(Math.round(time) / 60)
				.toString()
				.padStart(2, '0')}:${(Math.round(time) % 60).toString().padStart(2, '0')}`;

		const operateList = [
			{
				title: '播放',
				description: '播放歌曲',
				icon: '',
				operate(onlyid: string | number) {
					//alert('执行播放操作' + onlyid);
					store.commit('musicPlayerControl', `${generateOnlyid()}#replaceListAndPlay#${JSON.stringify({ list: [...listData.value], playOnlyid: onlyid })}`);
				},
			},
			{
				title: '添加',
				description: '添加到播放列表',
				icon: '',
				operate(onlyid: string | number) {
					//alert('执行添加操作' + onlyid);
					store.commit(
						'musicPlayerControl',
						`${generateOnlyid()}#addList#${JSON.stringify({
							addLocal: -1 /*-1最后0最前1在第一首后面第二首前面，依次类推*/,
							data: listData.value.find((item: any) => item.onlyid === onlyid),
						})}`
					);
				},
			},
			{
				title: '下载',
				description: '下载歌曲',
				icon: '',
				operate(onlyid: string | number) {
					//alert('下载歌曲' + onlyid);
					//$message.error('功能未开发');
					alert('功能未开放');
				},
			},
		];
		return { listData, timeToString, operateList };
	},
});
