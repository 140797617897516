import { defineComponent, Ref, ref } from '@vue/composition-api';

import MusicSongList from '@/components/Article/MusicSongList';
import FetchData from '@/components/FetchData';
import Card from '@/components/Card';
import store from '@/store';

interface PlaylistDetail {
	onlyid: string | number;
	title: string;
	description: string;
	image: string;
}
interface DataFromList extends PlaylistDetail {
	belongSongsOnlyid: string[];
	belongSongs: any[];
}

export default defineComponent({
	components: { Card, MusicSongList, FetchData },
	setup(props, { root }: any) {
		const { $previewRefresh } = root;
		const playlistDetail: Ref<PlaylistDetail> = ref({
			onlyid: 'aaaaaaaaaaaa',
			title: '标题加载中',
			description: '简介加载中简介加载中简介加载中简介加载中简介加载中',
			image: store.state.limitImgUrl,
		});
		const songsData: Ref<any[]> = ref([
			{
				onlyid: 'd_7eb5f051d1f4585c9c073776d46113337d84e99f',
				title: '我们的歌',
				author: '丽江小倩',
				duration: 327.01,
			},
			{
				onlyid: '2',
				title: '歌曲占位符',
				author: [
					'未知1',
					{
						onlyid: 'asudq23jhasdqw',
						title: '未知2',
					},
				],
				duration: 327.01,
			},
			{
				onlyid: '3',
				title: '无法播放的歌曲',
				author: '未知歌手',
				duration: 327.01,
				deterplay: true,
			},
		]);
		const touchData = (data: DataFromList) => {
			songsData.value = data.belongSongs;
			const { onlyid, title, description, image } = data;
			playlistDetail.value = { onlyid, title, description, image };
			$previewRefresh();
		};

		return { playlistDetail, songsData, touchData };
	},
});
